* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --text-light: #211f1f;
  --bg-light: #f2f3f4;
  --text-dark: ivory;
  --bg-dark: #211f1f;
  --inverted-light: #211f1f;
  --inverted-dark: #f2f3f4;
  --brandcolor1: #aa828c;
  --brandcolor2: #1a5e67;
  --brandcolor3: #f5c654;
}

@media (prefers-color-scheme: light) {
  :root {
    --text: var(--text-light);
    --bg: var(--bg-light);
    --inverted: var(--inverted-light);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --text: var(--text-dark);
    --bg: var(--bg-dark);
    --inverted: var(--inverted-dark);
  }

  .footer .row svg {
    filter: invert();
  }
}

[data-theme="dark"] {
  --text: var(--text-dark);
  --bg: var(--bg-dark);
  --inverted: var(--inverted-dark);
}

[data-theme="light"] {
  --text: var(--text-light);
  --bg: var(--bg-light);
  --inverted: var(--inverted-light);
}

.theme-switch-wrapper {
  align-items: center;
  display: flex;
  position: absolute;
}

.theme-switch {
  height: 34px;
  width: 60px;
  display: inline-block;
  position: relative;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: var(--brandcolor2);
  cursor: pointer;
  transition: all .4s;
  position: absolute;
  inset: 0;
  transform: scale(.6);
}

.slider:before {
  opacity: .9;
  content: "";
  height: 26px;
  width: 26px;
  background-color: #fff;
  transition: all .4s;
  position: absolute;
  bottom: 4px;
  left: 4px;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

@media (min-width: 320px) {
  .theme-switch-wrapper {
    bottom: 78px;
    left: 110px;
  }

  .slider {
    transform: scale(.5);
  }
}

@media (min-width: 480px) {
  .theme-switch-wrapper {
    bottom: 78px;
    left: 115px;
  }

  .slider {
    transform: scale(.5);
  }
}

@media (min-width: 768px) {
  .theme-switch-wrapper {
    bottom: 78px;
    left: 145px;
  }

  .slider {
    transform: scale(.6);
  }
}

@media (min-width: 1400px) {
  .theme-switch-wrapper {
    bottom: 123px;
    left: 195px;
  }

  .slider {
    transform: scale(.5);
  }
}

.scroll-line {
  height: .2em;
  z-index: 999;
  background: linear-gradient(217deg, #b9b9b9cc, #f000 70.71%), linear-gradient(127deg, #c6c7c6bb, #0f00 70.71%), linear-gradient(336deg, #b5b6b6cc, #00f0 70.71%);
  transition: all .5s cubic-bezier(.075, .82, .165, 1);
  position: fixed;
  bottom: 0;
  left: 0;
}

body {
  background-color: var(--bg);
  overflow-x: hidden;
}

.section, section {
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

.divider {
  padding-top: 5rem;
}

h1 {
  color: var(--text);
  text-align: center;
  font-family: Mulish, sans-serif;
}

h2 {
  color: var(--text);
}

h3 {
  color: var(--text);
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 400;
  display: block;
}

h4 {
  color: #5a5a5a;
  text-transform: uppercase;
  font-size: 2.3rem;
}

img {
  z-index: 1;
}

ul {
  padding: 0;
  list-style: none;
}

a {
  color: #d4a628;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #2e2e2e;
}

.logo {
  max-width: 100%;
  height: auto;
  width: auto\9 ;
  z-index: 999;
  margin-top: .2rem;
  display: block;
  position: absolute;
  top: 15px;
  left: 18px;
}

.header {
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  z-index: 10;
  margin: 0 auto;
  display: flex;
  position: fixed;
  overflow: hidden;
  height: 90px !important;
}

.main {
  height: 100%;
  margin: 60px auto 0;
  display: block;
}

.mainInner {
  height: 100%;
  width: 100%;
  text-align: center;
  display: table;
}

.mainInner div {
  vertical-align: middle;
  letter-spacing: 1.25px;
  font-size: 3em;
  font-weight: bold;
  display: table-cell;
}

#sidebarMenu {
  height: 100%;
  width: 300px;
  background: var(--bg);
  z-index: 998;
  margin-top: 0;
  transition: transform .2s ease-in-out;
  position: fixed;
  right: 0;
  transform: translateX(300px);
}

.sidebarMenuInner {
  border-top: 5px solid #d7dbdfe6;
  margin: 0;
  padding: 0;
}

.sidebarMenuInner li {
  color: #2c2c2c;
  text-transform: uppercase;
  cursor: pointer;
  padding: 20px;
  font-weight: bold;
  list-style: none;
}

.sidebarMenuInner li span {
  color: #ffffff80;
  font-size: 14px;
  display: block;
}

.sidebarMenuInner li a {
  color: var(--text);
  text-transform: uppercase;
  cursor: pointer;
  font-family: Mulish, sans-serif;
  font-weight: 400;
  text-decoration: none;
}

input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

input[type="checkbox"] {
  box-sizing: border-box;
  transition: all .3s;
  display: none;
}

.sidebarIconToggle {
  box-sizing: border-box;
  cursor: pointer;
  z-index: 999;
  height: 100%;
  width: 100%;
  height: 22px;
  width: 22px;
  transition: all .3s;
  position: fixed;
  top: 35px;
  right: 30px;
}

.spinner {
  box-sizing: border-box;
  height: 3px;
  width: 100%;
  background-color: var(--text);
  transition: all .3s;
  position: absolute;
}

.horizontal {
  box-sizing: border-box;
  float: left;
  margin-top: 3px;
  transition: all .3s;
  position: relative;
}

.diagonal.part-1 {
  box-sizing: border-box;
  float: left;
  transition: all .3s;
  position: relative;
}

.diagonal.part-2 {
  box-sizing: border-box;
  float: left;
  margin-top: 3px;
  transition: all .3s;
  position: relative;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  box-sizing: border-box;
  opacity: 0;
  transition: all .3s;
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  box-sizing: border-box;
  margin-top: 8px;
  transition: all .3s;
  transform: rotate(135deg);
}

input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  box-sizing: border-box;
  margin-top: -9px;
  transition: all .3s;
  transform: rotate(-135deg);
}

.nav-contactinfo {
  padding-top: 2rem;
  padding-left: 1.3rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.nav-contactinfo a {
  color: var(--text);
}

.nav-contactinfo li {
  padding-top: .7rem;
}

.footer-social-icons {
  width: 350px;
  margin: 0 auto;
  display: block;
}

ul.social-icons {
  margin-top: 25px;
  padding-left: 20px;
}

.social-icons li {
  vertical-align: top;
  height: 100px;
  display: inline;
}

.social-icons svg {
  fill: var(--inverted);
  width: 35px;
  height: 35px;
}

.social-icons svg:hover {
  transition: all .5s;
}

.svg-facebook:hover {
  fill: var(--brandcolor1);
}

.svg-twitter:hover {
  fill: var(--brandcolor2);
}

.svg-youtube:hover {
  fill: var(--brandcolor3);
}

.home {
  min-width: 100vw;
  max-height: 100vh;
  opacity: 1;
  background-image: url("bg1-2.ab251d38.webp");
  background-position: 52%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 800px) {
  .home {
    background-image: url("bg1-3.c3bd2382.webp");
    background-position: 70%;
    background-size: cover;
  }
}

@media (max-width: 400px) {
  .home {
    background-image: url("bg1-3.afa51f49.webp");
    background-position: 50%;
    background-size: cover;
  }
}

.item-right {
  background-image: url("bg-about.8da37d4e.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about {
  opacity: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.services {
  opacity: 1;
  background-position: 40%;
  background-repeat: no-repeat;
  background-size: cover;
}

.process {
  opacity: 1;
}

.residential {
  opacity: 1;
  background-position: 70%;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
}

.commercial {
  opacity: 1;
  background-position: 40%;
  background-repeat: no-repeat;
  background-size: contain;
}

.gallery {
  opacity: 1;
  background-position: 60%;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact {
  opacity: 1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 800px) {
  .contact {
    background-position: 53%;
    background-size: 150%;
  }
}

.fullscreen-container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.main-container {
  height: 100vh;
  width: 80vw;
  z-index: 990;
  flex-direction: column;
  flex-shrink: 2;
  justify-content: left;
  padding-bottom: 7vmin;
  display: flex;
  position: absolute;
}

.midscreen-container {
  height: 50vh;
  width: 100vw;
  position: relative;
}

.mid-container {
  padding-left: 4rem;
}

.main-title {
  color: #fff;
  text-transform: uppercase;
  max-width: 420px;
  font-family: Mulish, sans-serif;
  font-weight: 300;
  display: flex;
  position: absolute;
}

.main-title-light {
  color: var(--text);
  text-transform: uppercase;
  max-width: 420px;
  font-family: Mulish, sans-serif;
  font-size: 7rem;
  line-height: 5rem;
  position: absolute;
  bottom: 10rem;
}

.secondary-title {
  color: #fff;
  text-transform: uppercase;
  margin-left: 6px;
  font-family: Mulish, sans-serif;
  font-weight: 600;
  position: absolute;
}

.paragraph-title {
  align-content: center;
  display: flex;
}

.paragraph-title-center {
  place-content: center;
  padding-bottom: 4rem;
  display: flex;
}

.paragraph-title h3 {
  color: var(--text);
  border: 1px solid var(--inverted);
  padding: 4px;
}

.paragraph-title-center h3 {
  color: var(--text);
  border: 1px solid var(--inverted);
  padding: 4px;
  font-size: 1.5rem;
}

.paragraph-header {
  color: var(--text);
  max-width: 70%;
  padding-top: 3rem;
  font-family: Mulish, sans-serif;
}

.paragraph {
  color: var(--text);
  padding-top: 40px;
  font-family: Mulish, sans-serif;
  position: relative;
}

.paragraph p {
  opacity: .7;
  font-weight: 300;
}

@media (min-width: 320px) {
  .main-container {
    margin-left: 3rem;
    padding-top: 35vmin;
  }

  .main-title {
    max-width: 20px;
    font-size: 2.7rem;
    font-weight: 600;
    line-height: 3rem;
    bottom: 5rem;
  }

  .main-title-light {
    max-width: 20px;
    font-size: 1.8rem;
    bottom: 2rem;
  }

  .secondary-title {
    max-width: 90vw;
    font-size: .8rem;
    line-height: 1.5rem;
    bottom: 3.5rem;
  }

  .paragraph-title h3 {
    font-size: .9rem;
  }

  .paragraph-header {
    max-width: 80vw;
    padding-top: 1.6rem;
    font-size: 1.3rem;
    line-height: 2rem;
  }

  .paragraph {
    padding-top: 30px;
    font-size: 1rem;
    line-height: 1.3rem;
  }
}

@media (max-width: 480px) {
  .quote-section {
    display: none;
  }
}

@media (min-width: 480px) {
  .main-container {
    margin-left: 4.2rem;
    padding-top: 25vmin;
  }

  .main-title {
    max-width: 20px;
    font-size: 2.8rem;
    line-height: 3rem;
    bottom: 5rem;
  }

  .main-title-light {
    font-size: 2.8rem;
    line-height: 5rem;
    bottom: 2rem;
  }

  .secondary-title {
    max-width: 90vw;
    font-size: .8rem;
    line-height: 1.5rem;
    bottom: 3.5rem;
  }

  .paragraph-title h3 {
    font-size: 1rem;
  }

  .paragraph-header {
    max-width: 78vw;
    padding-top: 2rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .paragraph {
    padding-top: 30px;
    font-size: 1rem;
    line-height: 1.3rem;
  }
}

@media (min-width: 768px) {
  .main-container {
    margin-left: 4.2rem;
    padding-top: 15vmin;
  }

  .main-title {
    font-size: 3.2rem;
    line-height: 3rem;
    bottom: 5rem;
  }

  .main-title-light {
    font-size: 3.5rem;
  }

  .secondary-title {
    max-width: 90vw;
    font-size: .9rem;
    line-height: 1.5rem;
  }

  .main-title-light {
    font-size: 3rem;
    line-height: 5rem;
    bottom: 3rem;
  }

  .paragraph-title h3 {
    font-size: 1rem;
  }

  .paragraph-header {
    max-width: 75vw;
    padding-top: 2.6rem;
    font-size: 2.2rem;
    line-height: 2.5rem;
  }

  .paragraph {
    padding-top: 30px;
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}

@media (min-width: 992px) {
  .main-container {
    margin-left: 4.5rem;
    padding-top: 10vmin;
  }

  .main-title {
    font-size: 4.4rem;
    line-height: 4.1rem;
    bottom: 7rem;
  }

  .secondary-title {
    bottom: 6.2rem;
  }

  .main-title-light {
    font-size: 3rem;
    line-height: 5rem;
    bottom: 3rem;
  }

  .paragraph-title h3 {
    font-size: 1rem;
  }

  .paragraph-header {
    max-width: 55vw;
    padding-top: 1.6rem;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  .paragraph {
    padding-top: 25px;
    font-size: 1.1rem;
    line-height: 1.6rem;
    position: relative;
  }
}

@media (min-width: 1200px) {
  .main-container {
    max-width: 100vw;
    margin-left: 4.5rem;
    padding-top: 16vmin;
  }

  .main-title {
    font-size: 3.3rem;
    line-height: 3.2rem;
    bottom: 5rem;
  }

  .secondary-title {
    font-size: 1.1rem;
    bottom: 3.5rem;
  }

  .main-title-light {
    font-size: 3.5rem;
    line-height: 5rem;
    bottom: 3rem;
  }

  .paragraph-title h3 {
    font-size: 1.1rem;
  }

  .paragraph {
    padding-top: 1.3rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    position: relative;
  }

  .paragraph-header {
    max-width: 60vw;
    padding-top: 1.9rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1400px) {
  .main-container {
    max-width: 100vw;
    margin-left: 8rem;
    padding-top: 16vmin;
  }

  .main-title {
    font-size: 4.9rem;
    line-height: 4.2rem;
    bottom: 8rem;
  }

  .secondary-title {
    bottom: 6.4rem;
  }

  .main-title-light {
    font-size: 4rem;
    line-height: 5rem;
    bottom: 3rem;
  }

  .paragraph-title h3 {
    font-size: 1.2rem;
  }

  .paragraph {
    padding-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    position: relative;
  }

  .paragraph-header {
    max-width: 60vw;
    padding-top: 2rem;
    font-size: 3rem;
    line-height: 3rem;
  }
}

.about-container {
  min-height: 100vh;
  place-content: center;
  font-family: Mulish, sans-serif;
  display: flex;
}

.item {
  width: 50%;
  min-height: 500px;
}

.item-right {
  width: 50%;
  height: auto;
  min-height: 500px;
}

@media screen and (max-width: 800px) {
  .about-container {
    flex-direction: column;
  }

  .item, .item-right {
    width: 100vw;
    min-height: 30vh;
  }
}

@media (min-width: 320px) {
  .item {
    padding: 1.5em;
  }
}

@media (min-width: 480px) {
  .item {
    padding-top: 6rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 768px) {
  .item {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 992px) {
  .item {
    padding-top: 8rem;
  }
}

@media (min-width: 1200px) {
  .item {
    padding: 9rem 6em 6em;
  }
}

@media (min-width: 1400px) {
  .item {
    padding: 12rem 6em 6em;
  }
}

.collectionEl {
  max-width: 95%;
  justify-content: space-between;
  padding-top: 5rem;
  display: flex;
}

.innerEl {
  color: var(--text);
  box-shadow: 0 0 2px var(--inverted);
  text-align: center;
  border-radius: .3rem;
  margin-right: 1rem;
}

.inner-a {
  text-transform: uppercase;
  border-radius: 0;
  padding: 1em;
}

.inner-b {
  text-transform: uppercase;
  border-color: var(--brandcolor2);
  border-radius: 0;
  padding: 1em;
}

.inner-c {
  text-transform: uppercase;
  border-radius: 0;
  padding: 1em;
}

.outerE1 {
  color: var(--brandcolor1);
}

.collectionEl .outerEl {
  width: 30vw;
  justify-content: center;
  display: flex;
}

.collectionEl .innerEl {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.collectionEl .innerEl p + p {
  margin-top: 0;
}

@media (min-width: 320px) {
  .collectionEl {
    flex-flow: wrap;
  }
}

@media (min-width: 480px) {
  .collectionEl {
    flex-flow: wrap;
  }
}

@media (min-width: 992px) {
  .collectionEl {
    flex-flow: wrap;
  }
}

@media (min-width: 1200px) {
  .collectionEl {
    flex-flow: row;
  }
}

@media (min-width: 1400px) {
  .collectionEl {
    flex-flow: row;
  }
}

.col-text {
  height: 40em;
}

.grid-flex {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
}

.col {
  flex: 1;
}

.col-left {
  order: -1;
}

.col-text {
  justify-content: center;
  align-items: center;
  display: flex;
}

.col h4 {
  text-transform: uppercase;
  color: var(--text);
  padding-bottom: 1rem;
  font-family: Mulish, sans-serif;
  font-weight: 400;
  line-height: 2rem;
}

.col p {
  color: var(--text);
  opacity: .7;
  font-family: Mulish, sans-serif;
  font-size: 1.1rem;
}

.Aligner-item {
  width: 60%;
}

.col-image {
  background-position: center;
  background-size: cover;
}

@media (max-width: 640px) {
  .grid-flex {
    height: 40em;
    flex-direction: column;
  }

  .col {
    order: vertical;
  }

  .col-left {
    order: 0;
  }

  .col-text div p {
    padding: 1em;
  }

  .Aligner-item {
    width: 90%;
  }

  .col h4 {
    font-size: 1.4rem;
  }
}

label[for] {
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
}

.lightbox {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.grid {
  width: 100%;
  position: relative;
}

.lightbox {
  z-index: 1;
  min-height: 100%;
  transition: transform .5s ease-out, -webkit-transform .5s ease-out;
  overflow: auto;
  transform: scale(0);
}

.lightbox img {
  max-width: 96%;
  max-height: 96%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 8px #00000040;
}

input[type="checkbox"]:checked + .lightbox {
  transform: scale(1);
}

input[type="checkbox"]:checked ~ .grid {
  opacity: .125;
}

.grid {
  height: 100%;
  z-index: 0;
  text-align: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  padding: 80px 0 0;
  transition: opacity .75s;
  display: flex;
  overflow: auto;
}

.grid .grid-item {
  width: 20%;
  opacity: .95;
  padding: 5px;
  transition: opacity .5s;
  display: block;
}

.grid .grid-item:hover {
  opacity: 1;
}

@media screen and (max-width: 880px) {
  .grid .grid-item {
    width: 33.3333%;
  }
}

@media screen and (max-width: 480px) {
  .grid .grid-item {
    width: 50%;
  }
}

.grid img {
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 2px 8px #00000040;
}

.faqs {
  padding-top: 6rem;
}

.faq-heading {
  text-indent: 20px;
  text-align: center;
  color: var(--text);
  font-family: Mulish, sans-serif;
  font-size: 19px;
  font-weight: 400;
}

.faq-text {
  color: var(--text);
  width: 95%;
  text-align: center;
  margin-bottom: 30px;
  padding-top: 2rem;
  padding-left: 20px;
  font-family: Mulish, sans-serif;
  font-weight: 400;
}

.faq {
  width: 1000px;
  background: var(--text);
  max-width: 100vw;
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
}

.faq label {
  cursor: pointer;
  height: 56px;
  background-color: var(--bg);
  padding-top: 1px;
  display: block;
  position: relative;
  overflow: hidden;
}

.faq input[type="checkbox"] {
  display: none;
}

.faq .faq-arrow {
  width: 5px;
  height: 5px;
  float: right;
  border-top: 2px solid #00000054;
  border-right: 2px solid #00000054;
  transition: transform .8s, -webkit-transform .8s;
  position: relative;
  top: -30px;
  right: 27px;
  transform: rotate(45deg);
}

.faq input[type="checkbox"]:checked + label > .faq-arrow {
  transition: transform .8s, -webkit-transform .8s;
  transform: rotate(135deg);
}

.faq input[type="checkbox"]:checked + label {
  background: var(--bg);
  color: #4f7351;
  height: 230px;
  transition: height .8s;
  -webkit-transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  display: block;
}

.faq input[type="checkbox"]:not(:checked) + label {
  height: 58px;
  transition: height .8s;
  -webkit-transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
  display: block;
}

.footer {
  text-align: center;
  padding: 30px 0;
  font-family: Mulish, sans-serif;
}

.footer .row {
  width: 100%;
  color: var(--text);
  margin: 0%;
  padding: .6% 0%;
  font-size: .8em;
}

.footer .row svg {
  fill: var(--inverted);
}

.footer .row a {
  color: var(--text);
  text-decoration: none;
  transition: all .5s;
}

.footer .row a:hover {
  color: var(--text);
}

.footer .row ul {
  width: 100%;
}

.footer .row ul li {
  margin: 0 30px;
  display: inline-block;
}

.footer .row a i {
  margin: 0% 1%;
  font-size: 2em;
}

@media (max-width: 720px) {
  .footer {
    text-align: left;
    padding: 5%;
  }

  .footer .row ul li {
    text-align: left;
    margin: 10px 0;
    display: block;
  }

  .footer .row a i {
    margin: 0% 3%;
  }
}

/*# sourceMappingURL=main.bdc09530.css.map */
